html,
body {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.bytemd {
  height: calc(100vh - 200px) !important;
}

.markdown-body img {
  max-width: 100% !important;
  padding: 20px 5%;
}
