$size-1: 4rem;
$size-2: 3rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1.12rem;
$size-7: 1rem;
$size-8: 0.875rem;

$H0: 1.875rem;
$H1: 1.5rem;
$H2: 1.25rem;
$H3: 1.125rem;
$P1: 1rem;
$P2: 0.875rem;

$LH1: 3;
$LH2: 2.5;
$LH3: 2;
$LH4: 1.75;
$LH5: 1.5;
$LH6: 1.25;
$LH7: 1;
$LH8: 0.5;

$W1-ZH: 300;
$W2-ZH: 400;
$W3-ZH: 600;

$W1-EN: 500;
$W2-EN: 600;
$W3-EN: 700;

$M1: #ffffff; // Card Background
$M2: #f7f8f9; // Main Background

$F1: #2c2c2c; // Text - 1 Title，H0，H1，H2
$F2: #505050; // Text - 2 General，P1，P2，H3

$C1: #e7f0fc;
$C2: #dbe4f0;
$C3: #b8c4d6;
$C4: #9fa8b9;
$C5: #5d6d86;

$B1: #3d3fea; // Theme，Button, highlight, link
$B2: #27bbe0; // Blue，Minor
$B3: #4c5470; // Dark 100%，dark bg
$B4: #2d3758; // Dark 85%， bg

$T1: #a9d359; // tag，Success
$T2: #e9eaee; // line，divider, 10 % B3
$T3: #f9746c; // tag, Warning, Notice, Attention
$T4: #7d3f98; // tag
$T5: #f8c200; // tag
$T6: #f15a24; // tag

$color-mva: #b49243;

$family-sans-serif: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$primary: $B1;
$button-hover-color: #5051c9;

// widescreen false
$widescreen-enabled: false;

$shadow: 0 0.1875em 0.5em rgba(#000, 0.06);
$shadow-hover: 0 0.375em 0.75em #eaeaea;

// box
$box-shadow: $shadow;
$box-radius: 0;

// card
$card-shadow: $shadow;

// navbar
$navbar-item-hover-background-color: #e9ecef;
$height-navbar: 5.25rem; // 84px
$height-navbar-mobile: 4.25rem; // 68px

// promotion
$height-promotion: 4rem;

// page
$padding-page-mobile: 1rem;

// title
$title-line-height: 1.2;

// mobile section
$mobile-section-padding: 1rem;

@mixin transition($property: all, $duration: 0.25s) {
  transition: $property $duration;
}

@mixin shadow($hover-translate: false) {
  @include transition;
  box-shadow: $shadow;
  @if ($hover-translate) {
    &:hover {
      box-shadow: $shadow-hover;
      transform: translate(0, -4px);
    }
  }
}

@mixin card {
  @include shadow;
  border-radius: 0;
  border: 1px solid $T2;
}
